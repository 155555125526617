:root {
	--primary-bg-color : #010207;
    --secondary-bg-color : #010308;
    --light-black : #141B1C;
    --dominant : #ffffff;
    --main-red: #FF4040;
    --main-blue : #1FAEFF;
    --night-blue : #0D0F18;
    --main-green : #00DD69;
    --dark-gray : #313236;
    --main-gray : #828282;
    --pale-gray : #EFEFEF;
    --dark-purple : #202338;
    --color-d4 : #4D4D4D3D;

    --320: 20rem;
    --90: 5.625rem;
    --72: 4.5rem;
    --64: 4rem;
    --54: 3.375rem;
    --48: 3rem;
    --40: 2.5rem;
    --32: 2rem;
    --24: 1.5rem;
    --20: 1.25rem;
    --18 : 18px;
    --16: 1rem;
    --14: 14px ;
    --12: .75rem;
    --10: .65rem;
    --8: .5rem;
    --6: 6px;

    --border-r-8: .5rem;
    --border-r-6: .375rem;
    --border-r-16: 1rem;
    --border-r-18: 1.125rem;
    --border-r-24: 1.5rem;
    --border-r-32: 2rem;
    --border-r-40: 2.5rem;
    --border-r-50: 50%;
}