@import url(./root.css);
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');

* {
    box-sizing: border-box;
    margin: 0;
}

*::-webkit-scrollbar {
    display: none;
    appearance: none;
}

svg:not(:root) {
    overflow: visible;
}

html {
    font-size: 16px;

}

body {
    font-family: "DM Sans", sans-serif;
    margin: 0;
    font-weight: 500;
    line-height: 150%;
    overflow-x: hidden;
}

.container {
    width: 90%;
    margin: auto;
}

.fixed-container {
    margin : 0 auto;
    padding: 0 var(--16);
    width : min(100%,1330px);
}

/* list */

ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

li {
    display: list-item;
    text-align: -webkit-match-parent;
}

/* text size */

h1, h2, h3, h4, h5, .h0, .h00 {
    line-height: 100%;
}

.h00 {
    font-size: 200px;
    /* font-size: var(--320); */
    line-height: 150%;
    letter-spacing: -10px;
}

.h0 {
    font-size: var(--72);
    line-height: 100%;
}

h1 {
    font-size: var(--64);
    letter-spacing: -2px;
}

h2 {
    font-size: var(--48);
    letter-spacing: 0px;
    line-height: 62px;
}

h3 {
    font-size: var(--24);
    font-weight: 500;
    line-height: 31.25px;
}

h4 {
    font-size: var(--20);
    font-weight: 500;
    line-height: 26.6px;
}

h5 {
    font-size: var(--16);
    font-weight: 500;
}

p {
    font-size: 1rem;
    line-height: 150%;
    white-space: pre-wrap;
}

.p-big {
    font-size: var(--24);
    line-height: 150%;
}

.p-medium {
    font-size: var(--14);
    line-height: 150%;
}

.p-small {
    font-size: var(--10);
    line-height: 150%;
}

.p-bold {
    font-weight: 600;
}

.p-700 {
    font-weight: 700;
}

.p-caps {
    text-transform: capitalize;
}

.p-maj::first-letter {
    text-transform: capitalize;
}

.p-italic {
    font-style: italic;
}

.line-h-85 {
    line-height: 85px;
}

/* text color style */

.gray-c {
    color: var(--main-gray);
}

.white-c {
    color: var(--dominant);
}

.green-c {
    color: var(--main-green);
}

.blue-c {
    color: var(--main-blue);
}

.red-c {
    color: var(--main-red);
}

.linethrough-p {
    text-decoration: line-through;
}

/* text alignement */

.p-centered {
    text-align: center;
}

.p-right {
    text-align: right;
}

.p-left {
    text-align: left;
}

/* paragraph shortcut */

.p-one-line {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.p-two-lines {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.p-three-lines {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.p-four-lines {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

/* hr style */

hr {
    width: 100%;
    border: none;
    border-bottom: 1px solid var(--pale-gray);
    margin: 1.5rem auto;
}

hr:last-child {
    display: none;
}

/* positions */

.relative {
    position: relative;
}

.absolute {
    position: absolute;
}

.bottom {
    bottom: 0;
}

/* overflow */

.hidden {
    overflow: hidden;
}

.visibility-hidden {
    visibility: hidden;
}

/* stickers style */

.sticker {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: max-content;
    padding: 0 var(--16);
    height: 28px;
    border-radius: var(--border-r-18);
    font-size: var(--12);
    transition: .3s cubic-bezier(0.175, 0.885, 0.32, 1.275);

}

.sticker-small {
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    padding: 0 var(--8);
    height: 20px;
    border-radius: var(--border-r-18);
    font-size: var(--10);
}

.sticker-c1 {
    background: var(--c1);
    color: var(--dominant);
}

.sticker-red {
    background: var(--red-main);
    color: var(--dominant);
}

.sticker-gray {
    background: var(--pale-gray);
    color: var(--dominant);
}

.sticker-orange {
    background: var(--orange-main);
    color: var(--dominant);
}

.sticker-black {
    background: var(--primary);
    color: var(--dominant);
}

.sticker-gray-c1 {
    background: var(--dim-gray);
    color: var(--c1);
}

.sticker-gray-black {
    background: var(--dim-gray);
    color: var(--primary);
}

.sticker-green {
    background: var(--green-main);
    color: var(--dominant);
}

.sticker-click:active {
    transform: scale(.9);
}

/* toasts */

.green-toast {
    border: 1px solid var(--main-green);
    background: #142723;
    border-radius: var(--border-r-18);
    /* border-bottom-left-radius: 0;
    border-top-left-radius: 0; */
    /* border-left: 8px solid var(--green-main); */
    padding: .75rem;
}

.blue-toast {
    border: 1px solid var(--blue-main);
    background: var(--dominant);
    border-radius: var(--border-r-18);
    /* border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    border-left: 8px solid var(--blue-main); */
    padding: .75rem;
}

.orange-toast {
    border: 1px solid var(--orange-main);
    background: var(--dominant);
    border-radius: var(--border-r-18);
    /* border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    border-left: 8px solid var(--orange-main); */
    padding: .75rem;
}

.red-toast {
    border: 1px solid var(--main-red);
    background: #352323;
    /* background: var(--red-side); */
    border-radius: var(--border-r-18);
    /* border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    border-left: 8px solid var(--red-main); */
    padding: .75rem;
}

.toast-message {
    width: 400px;
    position: fixed;
    left: 32px;
    bottom: 32px;
    z-index: 10000;
    opacity: 0;
}

/* button style */

.btn {
    font-family: "DM Sans", sans-serif;
    border-radius: var(--border-r-24);
    cursor: pointer;
    transition: .2s ease-in;
    background: transparent;
    border: none;
}

.btn::first-letter {
    text-transform: capitalize;
}

.btn-dmnd {
    background-color: transparent;
    border: 1px solid var(--g7);
    color: var(--dominant);
}

.btn-send {
    background-color: var(--dark-gray);
    width: var(--48);
    height: var(--48);
    border-radius: var(--border-r-50);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.btn-text {
    background-color: transparent;
    border: none;
    color: var(--c3);
}

.btn-text-red {
    background-color: transparent;
    border: none;
    color: var(--red-main);
}

.btn-text-pale {
    background-color: transparent;
    border: none;
    color: var(--c1);
}

.btn-text-pale:hover {
    opacity: .5;
}

.btn-text-gray {
    background-color: transparent;
    border: none;
    color: var(--gray);
    text-decoration: underline;
    padding: 0;
}

.btn-text-gray:hover {
    color: var(--primary);
}

.btn-text-gray:hover * {
    color: var(--primary);
}

.btn-red {
    background-color: var(--red-main);
    border: none;
    color: var(--dominant);
}

.btn-red:hover {
    background-color: var(--red-side);
    border: none;
    color: var(--red-main);
}

.btn-gst-red {
    background-color: transparent;
    border: 1px solid var(--red-main);
    color: var(--red-main);
}

.btn-gst-red-disabled {
    background-color: transparent;
    border: 1px solid var(--dim-gray);
    color: var(--dim-gray);
    cursor: not-allowed;
}

.btn-gst-red:hover {
    background-color: var(--red-main);
    color: var(--dominant);
}
.btn-gst-orange {
    background-color: transparent;
    border: 1px solid var(--orange-main);
    color: var(--orange-main);
}

.btn-gst-orange-disabled {
    background-color: transparent;
    border: 1px solid var(--dim-gray);
    color: var(--dim-gray);
    cursor: not-allowed;
}

.btn-gst-orange:hover {
    background-color: var(--orange-main);
    color: var(--dominant);
}

.btn-disabled {
    background-color: var(--dim-gray);
    border: none;
    color: var(--dominant);
    cursor: not-allowed;
}

.btn-normal-pale {
    background-color: var(--c1);
    border: none;
    color: var(--dominant);
}

.btn-normal-pale:hover {
    background-color: var(--dominant);
    border: none;
    color: var(--c1);
}

.btn-plus, .btn-minus {
    position: relative;
    height: var(--54);
    width: var(--54);
    font-size: var(--24);
    border: 1px solid var(--pale-gray);
    background-color: var(--dominant);
    border-radius: var(--border-r-50);
    cursor: pointer;
    transition: .2s ease-in;
}

.btn-plus::after {
    position: absolute;
    content: '+';
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
}

.btn-minus::after {
    position: absolute;
    content: '-';
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
}

.btn-plus:hover, .btn-minus:hover {
    filter: invert();
}

.btn-full {
    width: 100%;
}

.btn-tooltip {
    position: relative;
}

.btn-tooltip::after {
    display: none;
    position: absolute;
    content: attr(data-tooltip);
    max-width: 100%;
    top: -100%;
    left: 0;
    font-size: 11px;
    background-color: var(--dominant);
    border: 1px solid var(--dim-gray);
    border-radius: var(--10);
    color: var(--gray);
    padding: .5rem;
    box-shadow: 0px 4px 65px -16px rgba(0, 0, 0, 0.29);
    transform: scale(0);
    animation-name: popout;
    animation-duration: .2s;
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
    animation-fill-mode: forwards;
}

.btn-tooltip:hover.btn-tooltip::after {
    display: block;
}


/* input fields style */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
/* input[type=number] {
    -moz-appearance: textfield;
} */

input[type=number]:focus {
    outline: none;
}

input[type=range] {
    /* -webkit-appearance: none; */
    width: 100%;
    height: 3px;
    background: var(--c1);
    outline: none;
    border-radius: var(--border-r-18);
}

input[type=range]::-ms-fill-upper {
    background: var(--c1);
}

.ifc {
    border-radius: var(--border-r-18);
    background-color: var(--dim-gray);
    color: var(--primary);
    width: 100%;
    height: 54px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ifc input {
    background-color: transparent;
    border: none;
    font-family: "DM Sans", sans-serif;
    font-size: var(--16);
    width: 100%;
    padding: 0 3rem 0 1.5rem;
}

.ifc input:focus {
    outline: none;
}

.if {
    font-family: "DM Sans", sans-serif;
    font-size: var(--16);
    padding: 0 var(--20);
    height: var(--54);
    border-radius: var(--border-r-24);
    background: none;
    border : 1px solid var(--dark-purple);
    color: #a1a4ac;
}

.if-one-char {
    width: var(--54);
}

.ifs-full {
    width: 100%;
}

.txt-area {
    font-family: "DM Sans", sans-serif;
    font-size: var(--16);
    padding: var(--16) var(--20);
    margin-right: 0;
    height: calc(var(--20)*7);
    border-radius: var(--border-r-18);
}

.ifs-small {
    width: 48.5%;
}

.ifs-tiny {
    width: 31.3%;
}

.if::placeholder {
    color: var(--gray);
}

.if:focus {
    /* outline: none; */
    outline: 2px solid var(--primary);
}

.if-disabled {
    opacity: .4;
}

.if-disabled:hover {
    cursor: not-allowed;
}

.if-no-border {
    border: none;
}

.if-no-border:focus {
    outline: none;
}

.disabled {
    cursor: not-allowed;
}

/* background color */

.bg-primary-body {
    background-color: var(--primary-color);
}

/* background images */

.bg-img {
    background-position: center center;
    background-repeat: no-repeat;
}

.contained-bg {
    background-size: contain;
}

.cover-bg {
    background-size: cover;
}

/* layout style */

.flex {
    display: flex;
}

.fx-scattered {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 8px;
}

.fx-centered {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 8px;
}

.fx-even {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    column-gap: 8px;
}

.fx-around {
    display: flex;
    justify-content: space-around;
    align-items: center;
    column-gap: 8px;
}

.fx-shrink {
    flex-shrink: 0;
}

.fx-col {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
}

.fx-start-v {
    align-items: flex-start;
}

.fx-start-h {
    justify-content: flex-start;
}

.fx-end-v {
    align-items: flex-end;
}

.fx-end-h {
    justify-content: flex-end;
}

.fx-stretch {
    align-items: stretch;
}

.fx-wrap {
    flex-wrap: wrap;
    row-gap: 12px;
}

.fx {
    flex: 1;
}

/* gaps */

.gap-6{
    gap: var(--6);
}

.gap-12 {
    gap: var(--12);
}

.gap-16 {
    gap: var(--16);
}

.gap-20 {
    gap: var(--20);
}

.gap-24 {
    gap: var(--24);
}

.gap-32 {
    gap: var(--32);
}

.gap-48 {
    gap: var(--48);
}



/* widths style */

.fit-container {
    width: 100%;
}

.w-80{
    width: 80%;
}
.w-70{
    width: 70%;
}
.w-60{
    width: 60%;
}


.half-container {
    width: 50%;
}

.w-40{
    width: 40%;
}

.qd-container {
    width: 42rem;
}

.fit-content {
    width: fit-content;
}

/* heights style */

.h-screen {
    height: 100vh;
}

.h-full {
    height: 100%;
}

.h-90{
    height: 90%;
}

.h-80{
    height: 80%;
}

.h-70{
    height: 70%;
}

.h-60{
    height: 60%;
}

.h-50{
    height: 50%;
}

.h-40{
    height: 40%;
}

.max-h-35 {
    max-height: 35px;
}

.max-h-1000 {
    max-height: 1000px;
}

/* white space style */

.box-pad-h {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

.box-pad-v {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
}

.box-pad-v-l {
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.box-pad-h-l {
    padding-left: 2rem;
    padding-right: 2rem;
}

.box-pad-h-m {
    padding-left: 1rem;
    padding-right: 1rem;
}

.box-pad-v-m {
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.box-pad-h-s {
    padding-left: .5rem;
    padding-right: .5rem;
}

.box-pad-v-s {
    padding-top: .5rem;
    padding-bottom: .5rem;
}

.box-marg {
    margin: 0 auto 3rem;
}

.box-marg-s {
    margin: 0 auto 1rem;
}

.box-marg-full {
    margin: 3rem auto;
}

.pb-large {
    padding-bottom: var(--90);
}

/* link style */

a {
    text-decoration: none;
    color: var(--primary);
}

.pointer {
    cursor: pointer;
}

/* toggles */

.toggle-c3, .toggle-c1, .toggle-dim-gray, .toggle-c2, .toggle-dim-gray-th {
    position: relative;
    min-height: 32px;
    min-width: 60px;
    /* padding: 0 var(--32) 0 0; */
    border-radius: var(--32);
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--dominant);
    overflow: hidden;
    transition: .3s ease-in-out;
    cursor: pointer;
}

.toggle-c3 {
    background-color: var(--c3);
}

.toggle-c2 {
    background-color: var(--c2);
}

.toggle-c1 {
    background-color: var(--c1);
}

.toggle-dim-gray, .toggle-dim-gray-th {
    background-color: var(--dim-gray);
}

.toggle-dim-gray::after, .toggle-c2::after {
    position: absolute;
    content: '';
    left: 2px;
    top: 2px;
    min-height: 28px;
    min-width: 28px;
    transform: translateX(100%);
    border-radius: var(--border-r-50);
    background: #FFFFFF;
    box-shadow: 0px 4px 12px -4px rgba(0, 0, 0, 0.25);
    transition: .2s ease-in-out;
}

.toggle-dim-gray-th::after {
    position: absolute;
    content: '';
    left: 2px;
    top: 2px;
    min-height: 28px;
    min-width: 28px;
    transform: translateX(calc(200% + 2px));
    border-radius: var(--border-r-50);
    background: #FFFFFF;
    box-shadow: 0px 4px 12px -4px rgba(0, 0, 0, 0.25);
    transition: .2s ease-in-out;
}

.toggle-c1::after, .toggle-c3::after {
    position: absolute;
    content: '';
    left: 2px;
    top: 2px;
    min-height: 28px;
    min-width: 28px;
    /* transform: translateX(2px); */
    border-radius: var(--border-r-50);
    background: #FFFFFF;
    box-shadow: 0px 4px 12px -4px rgba(0, 0, 0, 0.25);
    transition: .2s ease-in-out;
}

/* Progress */

.progress-circle {
    position: relative;
    width: fit-content;
    padding: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--dominant);
    border-radius: var(--border-r-50);
}

.progress-circle .label {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
}

.progress-bar {
    position: relative;
    width: 128px;
    height: 4px;
    background-color: var(--dim-gray);
    border-radius: var(--border-r-18);
    overflow: hidden;
}

.progress-bar div {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    border-radius: var(--border-r-18);
    background-color: var(--c1);
    transition: .2s ease-in;
}

.progress-bar-full {
    position: relative;
    width: 100%;
    margin: 1rem 0;
    height: 4px;
    background-color: var(--dim-gray);
    border-radius: var(--border-r-18);
    overflow: hidden;
}

.progress-bar-full div {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    border-radius: var(--border-r-18);
    background-color: var(--c1);
    transition: .2s ease-in;
}

/* others */

.overlay-bg, .overlay-bg-gradient {
    position: relative;
}

.overlay-bg::after {
    position: absolute;
    left: 0;
    top: 0;
    content: '';
    width: 100%;
    height: 100%;
    background-color: var(--c3-transparent-darker);
    z-index: -1;
}

.overlay-bg-gradient::after {
    position: absolute;
    left: 0;
    top: 0;
    content: '';
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 96.87%);
    z-index: 0;
}


.overlay-bg *, .overlay-bg-gradient * {
    position: relative;
    z-index: 1;
}

.title-mark, .title-mark-c1, .title-mark-primary {
    position: relative;
    padding-left: 1.5rem;
}

.title-mark::before, .title-mark-c1::before, .title-mark-primary::before {
    position: absolute;
    content: '';
    left: 0px;
    top: 0;
    height: 100%;
    width: 8px;
    border-radius: var(--border-r-18);
    animation-name: extend;
    animation-duration: .3s;
    animation-fill-mode: forwards;
}

.title-mark::before {
    background-color: var(--c2);
}

.title-mark-c1::before {
    background-color: var(--c1);
}

.title-mark-primary::before {
    background-color: var(--primary);
}

.sc-s {
    overflow: hidden;
    border-radius: var(--border-r-32);
    border: 1px solid var(--pale-gray);
    background-color: var(--dominant);
}

.sc-s-18 {
    overflow: hidden;
    border-radius: var(--border-r-18);
    border: 1px solid var(--pale-gray);
    background-color: var(--dominant);
}

.sc-s-t {
    overflow: hidden;
    border-radius: var(--border-r-32);
    background-color: transparent;
}

.sc-s-d {
    overflow: hidden;
    border-radius: var(--border-r-32);
    border: 1px dashed var(--pale-gray);
    background-color: var(--dominant);
    transition: .2s ease-in-out;
}

.sc-s-d:hover {
    border-color: var(--primary);
}

.v-separator {
    height: var(--54);
    width: 2px;
    margin-left: 1rem;
    margin-right: 1rem;
    background-color: var(--dim-gray);
}

.notifcation-dot {
    position: relative;
}

.notifcation-dot::after {
    position: absolute;
    right: 0px;
    top: 0px;
    content: '';
    width: 12px;
    height: 12px;
    background-color: var(--c1);
    border: 2px solid var(--dominant);
    border-radius: var(--border-r-50);
    transform: scale(0);
    animation-name: popout;
    animation-duration: .5s;
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
    animation-fill-mode: forwards;
}

.notifcation-dot-orange {
    position: relative;
}

.notifcation-dot-orange::after {
    position: absolute;
    right: 0px;
    top: 0px;
    content: '';
    width: 12px;
    height: 12px;
    background-color: var(--orange-main);
    border: 2px solid var(--dominant);
    border-radius: var(--border-r-50);
    transform: scale(0);
    animation-name: popout;
    animation-duration: .5s;
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
    animation-fill-mode: forwards;
}

.arrow-cir {
    cursor: pointer;
    border: 1px solid var(--pale-gray);
    border-radius: var(--border-r-50);
    background-color: var(--dominant);
    /* padding: 1rem; */
    min-height: var(--72);
    min-width: var(--72);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .1s ease-in-out;
}

.arrow-cir:hover * {
    filter: invert();
}

.arrow-cir:hover {
    background-color: var(--c3);
}

.arrow-right {
    transform: rotate(-180deg);
    background-color: var(--c3);
}

.arrow-right * {
    filter: invert();
}

.arrow-right:hover {
    background-color: var(--c1);
}

/* .close {
    position: absolute;
    left: 16px;
    top: 16px;
    transition: .2s ease-in-out;
    z-index: 10;
    animation-name: popout;
    animation-duration: .2s;
    transform: scale(0);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
    animation-fill-mode: forwards;
    animation-delay: .3s;
    cursor: pointer;
}

.close div {
    position: relative;
    width: 26px;
    height: 26px;
    border-radius: var(--border-r-50);
    background-color: var(--dim-gray);
}

.close div::after {
    position: absolute;
    content: '\2715';
    display: flex;
    justify-content: center;
    align-items: center;
    /* left: 50%;
    top: 50%; */
    /* transform: translateX(-50%) translateY(-50%); */
    /* width: 100%;
    height: 100%;
} */

.close:hover {
    filter: invert();
}

.list-item {
    transition: .1s ease-in;
}

.list-item:hover {
    border-color: var(--c1);
}

ul {
    padding-left: 15px;
}