.slide-down {
    opacity: 0;
    animation-name: fade-n-slide-down;
    animation-duration: .4s;
    animation-timing-function: cubic-bezier(0.99, 0.01, 0.03, 0.76);
    animation-fill-mode: forwards;
    animation-delay: 0.3s;
}

.slide-up {
    opacity: 0;
    animation-name: fade-n-slide-up;
    animation-duration: .4s;
    animation-timing-function: cubic-bezier(0.99, 0.01, 0.03, 0.76);
    animation-fill-mode: backwards;
}

.fade-up {
    opacity: 0;
    animation-name: fade-up;
    animation-duration: .8s;
    animation-timing-function: cubic-bezier(0.99, 0.01, 0.03, 0.76);
    animation-fill-mode: backwards;
    animation-delay: 0.4s;
}

.fade-down {
    opacity: 0;
    animation-name: fade-down;
    animation-duration: .8s;
    animation-timing-function: cubic-bezier(0.99, 0.01, 0.03, 0.76);
    animation-fill-mode: forwards;
}

.hide {
    opacity: 0;
    animation-name: fade-hide;
    animation-duration: .6s;
    animation-timing-function: cubic-bezier(0.99, 0.01, 0.03, 0.76);
    animation-fill-mode: forwards;
}

.sliding {
    animation-name: slide-right-left;
    animation-duration: 1.2s;
    animation-timing-function: cubic-bezier(0.99, 0.01, 0.03, 0.76);
    animation-iteration-count: infinite;
    animation-fill-mode: forwards;
}

.toast-message-animation {
    animation-name: fadeup-n-fadedown;
    animation-duration: 4s;
    animation-timing-function: cubic-bezier(0.99, 0.01, 0.03, 0.76);
  }


@keyframes fade-n-slide-down {
    0% {
        opacity: 0;
        transform: translateY(-100%);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fade-n-slide-up {
    0% {
        opacity: 1;
        transform: translateY(0);
    }

    50% {
        opacity: 0;
        transform: translateY(-30%);
    }
}

@keyframes fade-up {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes fade-down {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fade-hide {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 0;
    }
}

@keyframes slide-right-left {
    0% {
        transform: translate(0px, 0px);
    }

    20% {
        transform: translate(40px, -30px);
    }

    30% {
        transform: translate(30px, 25px);
    }

    40% {
        transform: translate(-30px, 40px);
    }

    60% {
        transform: translate(0px, 0px);
    }
}

@keyframes fadeup-n-fadedown {
    0% {
        opacity: 0;
        transform: translateY(100%);
    }
  
    10% {
        opacity: 1;
        transform: translateY(0);
    }
  
    90% {
        opacity: 1;
        transform: translateY(0);
    }
  
    100% {
        opacity: 0;
        transform: translateY(100%);
    }
  }