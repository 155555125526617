@import url(./root.css);

body {
    background-color: var(--primary-bg-color);
}

.bg-light-black {
    background-color: var(--light-black);
}

.nav-bar {
    position: sticky;
    top: 0;
    z-index: 100;
}

.nav-bar-mobile {
    display: none;
    position: sticky;
    top: 0;
    z-index: 100;
    padding: var(--24) var(--16);
}

.mobile-links-container {
    display: none;
}

.nav-bar::before,
.nav-bar-mobile::before {
    content: '';
    position: absolute;
    z-index: -1;
    height: 100%;
    background-color: #0102073b;
}

.nav-bar::before {
    min-width: 4000px;
    left: -100%;
}

.nav-bar-mobile::before {
    min-width: 110%;
    left: -5%;
}

.links {
    border-radius: var(--border-r-32);
}

.btn-cnct {
    background-color: var(--light-black);
    font-size: var(--16);
    font-weight: 500;
    border: none;
    padding: var(--14) var(--24);
    color: var(--dominant);
}

.links .link {
    padding: var(--16) var(--24);
    cursor: pointer;
}

.active-link {
    background-color: var(--dominant);
    border-radius: var(--border-r-32);
}

.active-link h5 {
    color: #000;
}


/* hero section */

.hero-container {
    padding-top: var(--90);
    padding-bottom: var(--16);
}

.hero-container .hero-section {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: var(--64);
}

.hero-container .hero-section .hero-title {
    line-height: 85px;
    padding-bottom: var(--8);
}

.hero-container .hero-section .hero-text {
    font-size: var(--20);
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn-text {
   font-size: var(--20);
   font-weight: 400;
   color: #E6E6E6;
}

.btn-rsrv {
    position: relative;
    background: #000;
    margin-top: 0.25rem;
    border-radius: var(--border-r-32);
    padding: var(--6);
    gap: var(--32);
}

.btn-rsrv:before {
    content: "";
    position: absolute;
    height: 107%;
    top: 3px;
    right: 0;
    bottom: 0;
    left: 3px;
    z-index: -10;
    width: 101.5%;
    margin: -5px;
    border-radius: inherit;
    background: linear-gradient(to right, var(--main-blue), var(--main-green));
}

.main-bg {
    background-image: url(../images/Group-1458.png);
    min-height: 1330px;
    min-width: 200%;
    background-repeat: no-repeat;
    background-position: center;
    filter: blur(30px);
}

.hero-container .hero-section .hero-bg {
    position: absolute;
    top: -7%;
}

.hero-container .hero-section .image-container {
    position: relative;
    aspect-ratio: 5 / 3;
    border-radius: var(--border-r-32);
    width: 100%;
    overflow: hidden;
}

.hero-container .hero-section .image-content {
    position: absolute;
    top: 60%;
    left: 50%;
    border-radius: var(--border-r-16);
    transform: translate(-50%,-51%);
    aspect-ratio: 22 / 15;
    background-position: top center;
    background-image: url(../images/Group-1457.jpg);
}

.video-container {
    width: 97.5%;
    height: 92%;
}

.video-content {
    background-color: #000;
    border-radius: var(--32);
    aspect-ratio: 1;
    min-width: 100%;
    min-height: 100%;
    width: 100% !important;
    height: 100% !important;
    overflow: hidden;
    object-fit: cover;
    /* transform: translateY(14px); */
}

.remaind-me {
    padding: var(--18) var(--32);
    border-radius: var(--32);
    z-index: 3;
    background-color: var(--color-d4);
}

/* solution */

.solution-container .solution-bg {
    position: absolute;
    z-index: -1;
}

.solution-container .wrapper {
    width: 98%;
    height: 90%;
    background-color: #4D4D4D3D;
    border-radius: var(--border-r-32);
    padding: var(--32) var(--16);
}

.solution-container .solution-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: var(--32);
    padding-top: var(--32);
}

.card-content {
    height: 360px;
    width: 50%;
    border-radius: var(--border-r-40);
    padding-top: var(--32);
    background-color: var(--pale-gray);
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    position: relative;
    overflow: hidden;
}

.card-content .card-text {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    gap: var(--16);
    padding: 0 var(--32);
}

.card-content .large-card-img {
    width: 93%;
    height: max-content;
    position: absolute;
    bottom: -30px;
}

.small-card-content {
    height: 330px;
    width: 31.33%;
    justify-content: space-between;
}

.small-card-img-container {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: end;
    position: absolute;
    left: 0;
    top: 0;
}

.small-card-img {
    max-width: 100%;
    max-height: 100%;
    width: max-content;
    height: max-content;
    background-color: var(--dominant);
    border-radius: var(--border-r-16);
}

.img-box {
    display: flex;
    justify-content: center;
    align-items: center;
}

.large-card-container,
.tasks-large-card-container {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}


/* outils */

.outils-overly::before {
    content: '';
    position: absolute;
    right: -1px;
    top: 0;
    height: 100%;
    width: 20%;
    background-image: linear-gradient(to right , #01020700, #010207);
}

.outils-overly::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 15%;
    background-image: linear-gradient(to right, #010207 , #01020700);
}

/* .outils-overly::before{
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background-image: url(../images/Rectangle-484.png);
    background-position: center;
    background-size: contain;
    z-index: 1;
} */

/* services */
.page-model-container {
    /* aspect-ratio: 2 / 2.10; */
    overflow: hidden;
    width: 100%;
    position: relative;
    background-color: var(--night-blue);
    border-radius: var(--border-r-40);
    transition: 0.3s;
}

.medium-card-h {
    min-height: 560px;
    max-height: 620px;
}

.high-card-h {
    min-height: 620px;
    max-height: 680px;
}

.page-model-txt {
    padding: var(--32);
}

.plus-container-right {
    right: 20px;
}

.plus-container-left {
    left: 20px;
}

.plus-container {
    position: absolute;
    bottom: 20px;
    height: 48px;
    width: 48px;
    background-color: var(--dark-gray);
    border-radius: var(--border-r-50);
    z-index: 10;
    transition: 0.6s;
}

.page-model-container .medium-h-card {
    aspect-ratio: 4 / 2;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.thirdparty_management_position {
    position: absolute;
    bottom: 0;
    left: 3%;
}

.custom-model-card {
    width: 50%;
}

/* connections */

.news-feed-card {
    background-color: #0D1812;
}

.direct-messages-card {
    background-color: #180D0D;
    aspect-ratio: 2 / 2.10;
}

.connection-card {
    width: 50%;
}

.app-sections {
    /* aspect-ratio: 6 / 4; */
    background-color: var(--dominant);
    padding-top: var(--54);
    border-radius: var(--border-r-40);
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    overflow: hidden;
}

.app-sections .text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: var(--24);
    text-align: center;
}

.app-sections .text h2 {
    padding-bottom: var(--16);
}

.detailed-text-info {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    transition: 1s;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: var(--32);
}

/* MedIA */

.med-ia-text {
    display: flex;
    flex-direction: column;
    gap: var(--24);
    justify-content: center;
    align-items: center;
}

.med-ia-text h2 {
    padding-bottom: var(--16);
}

.med-ia-services {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: var(--16);
}

.med-ia-services .service-card {
    width: 60%;
    min-height: 243px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    gap: var(--20);
    border-radius: var(--border-r-24);
    padding: var(--20);
    background-color: #14221F7D;
}

.med-ia-services .card-left {
    width: 100%;
    display: flex;
    justify-content: start;
}

.med-ia-services .card-right {
    width: 100%;
    display: flex;
    justify-content: end;
}

/* questions */

.questions-bg {
    position: absolute;
    z-index: -1;
    top: -150px;
}

.questions-container .question-data {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: var(--24);
    border-radius: var(--border-r-24);
    background-color: #4D4D4D3D;
    overflow: hidden;
    transition: 0.3s ease-out;
}

.question-data .paragraph {
    transition: 0.3s ease-out;
}

.close-button {
    transform: rotate(45deg);
    transition: 0.3s ease-out;
    filter: invert() brightness(100);
}

/* contact */

.contact {
    padding-top: var(--64);
    padding-bottom: var(--32);
}

.contact-section {
    display: flex;
    justify-content: space-between;
    gap: var(--32);
    align-items: center;
    background-color: #2C2C2C3D;
    border-radius: var(--border-r-32);
    padding: var(--64) var(--48) var(--48);
}

.contact-section .custom-rsrv-bg {
    background-color: var(--primary-bg-color);
}

/* footer */

.footer {
    padding: var(--32);
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top-left-radius: var(--border-r-24);
    border-top-right-radius: var(--border-r-24);
    background-color: #2C2C2C3D;
}

.footer-mobile {
    display: none;
}

.footer .footer-links {
    display: flex;
    gap: var(--32);
    align-items: center;
}

.footer .footer-socials {
    display: flex;
    gap: var(--14);
    align-items: center;
}