.logo,
.arrow,
.circle,
.dark_gradiant,
.outilsm,
.plus_sign,
.gifts,
.gifts_confirm,
.survey,
.thirdparty_management,
.tasks_management,
.comitologie,
.report_builder,
.dashboard,
.team,
.news_feed,
.direct_messages,
.mobile_app,
.med_ia,
.search,
.chart,
.todos,
.gray_plus_sign,
.close,
.send,
.instagram,
.facebook,
.linkedin,
.like,
.not_allowed,
.plan,
.add,
.plans,
.solution_carthographie,
.tasks_management_solution,
.media,
.survey_solution,
.risks_management,
.productivity,
.performance,
.connection_picture,
.refrence,
.arrow_down,
.cupc {
    margin: 0;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    /* cursor: pointer; */
    transition: .2s ease-in-out;
}



.instagram,
.facebook,
.linkedin {
    min-width: 18px;
    min-height: 18px;
}

.arrow {
    min-width: 18.38px;
    min-height: 16px;
}

.arrow_down {
    min-width: 20px;
    min-height: 11px;
}

.plus_sign,
.gray_plus_sign,
.close {
    min-width: 21px;
    min-height: 21px;
}

.send,
.add {
    min-width: 24px;
    min-height: 24px;
}


.cupc {
    min-width: 26px;
    min-height: 26px;
}

.like,
.not_allowed {
    min-width: 32px;
    min-height: 32px;
}

.circle {
    min-width: 42px;
    min-height: 42px;
}

.search,
.todos,
.chart {
    min-width: 48px;
    min-height: 48px;
}

.logo {
    min-width: 73px;
    min-height: 26px;
}

.dark_gradiant {
    min-width: 1440px;
    min-height: 520px;
}

.outils {
    min-width: 100%;
    min-height: 420px;
}



.gifts_confirm {
    width: 70%;
    height: 40%;
    background-position: bottom left;
}

.survey {
    width: 100%;
    height: 70%;
    background-position: bottom right;
}

.thirdparty_management {
    width: 100%;
    height: 76%;
    background-position: bottom left;
}

.tasks_management {
    width: 95%;
    height: 70%;
    background-position: bottom center;
}

.news_feed {
    width: 100%;
    height: 70%;
    background-position: bottom center;
}

.direct_messages {
    width: 90%;
    height: 57%;
    margin-bottom: var(--20);
    background-position: bottom center;
}

.med_ia {
    width: 80%;
    height: 850px;
}

.plans {
    height: 100%;
    width: 100%;
    background-position: bottom;
}

.survey_solution {
    height: 100%;
    width: 100%;
    background-position: bottom;
}

.media {
    height: 87%;
    width: 98%;
    background-position: bottom;
    background-color: var(--dominant);
    border-radius: var(--16);
}

.solution_carthographie {
    height: 100%;
    width: 100%;
    background-position: bottom;
}

.tasks_management_solution {
    height: 108%;
    width: 100%;
    background-position: bottom;
}

.risks_management {
    width: 100%;
    height: 100%;
    background-position: bottom;
}

.productivity,
.performance,
.connection_picture,
.refrence {
    width: 100%;
    height: 100%;
    background-position: bottom;
}


.team {
    aspect-ratio: 5 / 2;
}

.dashboard {
    aspect-ratio: 7 / 7.7;
}

.report_builder {
    aspect-ratio: 7 / 4.5;
}

.gifts {
    aspect-ratio: 13.5 / 4;
}

.comitologie {
    aspect-ratio: 7 / 4;
}

.mobile_app {
    width: 100%;
    aspect-ratio: 5 / 2.3;
    background-position: bottom;
    border-radius: var(--40);
}




.logo {
    background-image: url(../images/icons/Layer_1.svg);
}

.circle {
    background-image: url(../images/icons/Ellipse-18@2x.svg);
}

.arrow {
    background-image: url(../images/icons/Arrow-1@2x.svg);
}

.dark_gradiant {
    background-image: url(../images/Rectangle-484.png);
}

.outils {
    background-image: url(../images/Group-1612.svg);
}

.carthographie {
    background-image: url(../images/Group-1636.svg);
}

.gifts {
    background-image: url(../images/Group-1643.png);
}

.gifts_confirm {
    background-image: url(../images/Group-1006.png);
}

.plus_sign {
    background-image: url(../images/icons/plus_sign.svg);
}

.survey {
    background-image: url(../images/Group-1638.svg);
}

.thirdparty_management {
    background-image: url(../images/Group-1178.png);
}

.tasks_management {
    background-image: url(../images/Group-1656-2.png);
}

.comitologie {
    background-image: url(../images/Group-1662.svg);
}

.report_builder {
    background-image: url(../images/Group-1668.svg);
}

.dashboard {
    background-image: url(../images/Group-1665.svg);
}

.team {
    background-image: url(../images/Group-1728.png);
}

.news_feed {
    background-image: url(../images/Group-1686.png);
}

.direct_messages {
    background-image: url(../images/Group-1695.png);
}

.mobile_app {
    background-image: url(../images/Group-1696.png);
}

.med_ia {
    background-image: url(../images/image-2.png);
}

.search {
    background-image: url(../images/icons/search-normal.svg);
}

.todos {
    background-image: url(../images/icons/task.svg);
}

.chart {
    background-image: url(../images/icons/chart.svg);
}


.gray_plus_sign {
    background-image: url(../images/icons/Group-1716.svg);
}

.close {
    background-image: url(../images/icons/Group-1631.svg);
}

.send {
    background-image: url(../images/icons/send-2.svg); 
}

.instagram {
    background-image: url(../images/icons/image-5.svg); 
}

.facebook {
    background-image: url(../images/icons/image-6.svg);
}

.linkedin {
    background-image: url(../images/icons/image-4.svg);
}

.like {
    background-image: url(../images/icons/like.svg);
}

.not_allowed {
    background-image: url(../images/icons/notallowed.svg);
}

.add {
    background-image: url(../images/icons/add.svg);
}

.plans {
    background-image: url(../images/Group-1470.png);
}

.solution_carthographie {
    background-image: url(../images/Group-1463.svg);
}

.tasks_management_solution {
    background-image: url(../images/Group-1466.png);
}

.media {
    background-image: url(../images/Group-1472.png);
}

.survey_solution {
    background-image: url(../images/Group-1469.png);
}

.risks_management {
    background-image: url(../images/6.png);
}

.productivity {
    background-image: url(../images/33.png);
}

.performance {
    background-image: url(../images/32.png);
}

.connection_picture {
    background-image: url(../images/36.png);
}

.refrence {
    background-image: url(../images/35.png);
}

.arrow_down {
    background-image: url(../images/icons/Vector\ 5.svg);
}

.cupc {
    background-image: url(../images/icons/cupc.svg);
}