
@media only screen and (max-width :1200px){
    .medium-card-h {
        min-height: 570px;
        max-height: 580px;
    }
    
    .high-card-h {
        min-height: 580px;
        max-height: 640px;
    }

    .min-h-510px {
        min-height: 510px !important;
    }
}

@media only screen and (max-width :1050px){

    /* .fixed-container {
        overflow: hidden;
    } */

    .nav-bar {
        display: none;
    }

    .nav-bar-mobile {
        display: flex;
    }

    .mobile-links-container {
        position: fixed;
        display: flex;
        width: 100%;
        height: 100%;
        background-color: var(--secondary-bg-color);
        top: 0px;
        bottom: 0px;
        left: 100%;
        right: 0;
        z-index: 99;
        padding-top: 240px;
        transition: 0.3s all;
    }

    .move-right {
        left: 0;
    }
}

@media only screen and (max-width :950px){

    .high-card-h {
        min-height: 670px;
        max-height: 680px;
    }

    .sections-container {
        padding-left: 0;
        padding-right: 0;
    }

    .card-content {
        width: 48%;
    }

    /* contact */

    .contact-section {
        flex-direction: column;
        padding: var(--24) var(--16);
    }

    .contact-section .contact-text {
        width: 100%;
    }

    .contact-section form {
        width: 100%;
    }

    .contact-buttons {
        display: flex;
        flex-direction: column;
        gap: var(--16);
        max-width: 320px;
        margin: 0 auto;
    }

}

@media only screen and (max-width :850px){

    .sections-card-mobile {
        flex-direction: column;
    }

    .main-sections-container {
        flex-direction: column;
        padding: 0;
    }

    .card-content {
        width: 100%;
        height: 340px;
        margin: var(--16) 0;
    }
    
    .card-content .large-card-img {
        height: 72%;
        width: 62%;
    }

    .footer {
        display: none;
    }

    .other-sections-container {
        gap: 0 !important;
        padding: 0 !important;
    }

    .sections-data-mobile {
        row-gap: var(--24) !important;
    }

    .text-column {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: var(--16);
    }

    .text-column h2,
    .text-column p {
        text-align: center;
        width: 100% !important;
    }

    .text-column p {
        font-size: var(--16) !important;
    }

    .text-column > div {
        width: 100%;
    }

    .custom-model-card {
        width: 100%;
    }

    .services-sections-text-mobile {
        flex-direction: column;
        gap: var(--16);
    }

    .services-sections-text-mobile .title-content {
        width: 100%;
    }

    .services-sections-text-mobile h2 {
        width: 100%;
        text-align: center;
    }

    .services-sections-text-mobile p {
        width: 100% !important;
        transform: translateY(0) !important;
        text-align: center;
        font-size: var(--16) !important;
    }

    .tasks-services-mobile,
    .reports-services-mobile {
        flex-direction: column;
    }

    .service-column-mobile{
        width: 100% !important;
    }

    .service-column-mobile .text-data {
        gap: var(--16);
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    /* connection */

    .connection-text-mobile {
        gap: var(--16);
    }

    .connection-text-mobile p {
        width: 100%;
        font-size: var(--16) !important;
        text-align: center;
    }

    .connection-modules-mobile {
        flex-direction: column;
        padding-left: 0;
        padding-right: 0;
    }

    .news-feed-card {
        min-height: 550px !important;
    }

    .connection-card {
        width: 100%;
    }

    /* sloution */

    /* .small-card-img-container {
        max-width: 440px;
    } */

    /* footer */
    .footer-mobile {
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: start;
        gap: var(--24);
        padding: var(--24);
        border-top-left-radius: var(--border-r-24);
        border-top-right-radius: var(--border-r-24);
        background-color: #2C2C2C3D;
    }

    .footer-mobile-links {
        width: 100%;
    }

    /* images */

    .gifts {
        aspect-ratio: 15 / 4;
    }
}


@media only screen and (max-width :770px){

    .h0 {
        font-size: var(--54);
        line-height: 60px !important;
    }

    h1 {
        font-size: var(--48);
        line-height: 60px !important;
    }

    h2 {
        font-size: 38px;
        line-height: 45px !important;
    }

    h3 {
        font-size: var(--18);
    }

    .main-bg {
        min-width: 109%;
    }

    /* hero */

    .hero-container .hero-section .hero-title {
        padding-bottom: 0px;
    }

    .hero-container .hero-section .hero-text {
        width: 100%;
    }

    .video-container {
        width: 100%;
        height: 94%;
    }

    /* soulution */

    .solution-container .wrapper {
        width: 100%;
        padding: var(--24) var(--14);
    }

    .solution-container .solution-content {
        padding-top: 0;
    }

    /* services */

    .high-card-h {
        min-height: 580px;
        max-height: 600px;
    }

    /* connection */

    .app-sections {
        aspect-ratio: 0;
        padding-top: 0;
    }
    
    .app-sections .text {
        text-align: center;
        gap: var(--16);
        padding: var(--24);
    }

    .app-sections .max-h-35 {
        max-height: 0px !important;
    }

    .app-sections .text h2 {
        padding-bottom: 0;
        line-height: 40px;
    }

    .app-sections .text p {
        font-size: var(--16) !important;
        width: 100%;
    }

    .team {
        aspect-ratio: 5 / 3;
        position: relative;
        background-size: cover;
    }

    .team::before {
        content: '';
        position: absolute;
        right: -1px;
        top: 0;
        height: 100%;
        width: 10%;
        background-image: linear-gradient(to right , transparent, #000);
    }

    .team::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 10%;
        background-image: linear-gradient(to right, #000 , transparent);
    }

    /* MedIA */

    .med-ia-text {
        gap: var(--16);
    }

    .med-ia-text h2 {
        padding-bottom: 0;
    }

    .med-ia-text p {
        font-size: var(--16) !important;
    }

    .med-ia-services {
        width: 100%;
    }

    .service-card {
        width: 100% !important;
        height: auto !important;
    }

    /* outils */

    .outils-container h1 {
        width: 100%;
    }

    /* questions */

    .questions-text {
        text-align: center;
    }

    .questions-text p {
        font-size: var(--16) !important;
    }

    .questions-container {
        width: 100% !important;
    }

    /* contact */

    .contact-text-data {
        text-align: center;
    }

    /* pictures */
    .survey {
        position: absolute;
        right: 0;
    }

    .direct_messages {
        margin-bottom: var(--24);
    }

    .plans {
        width: 100%;
        height: 100%;
    }

    .media {
        height: 95%;
    }

    /* custom card heig */

    .min-h-560px {
        min-height: 560px !important;
    }

    .min-h-580px {
        min-height: 580px !important;
    }

    .min-h-620px {
        min-height: 620px;
    }

    .min-h-660px {
        min-height: 660px !important;
    }
}


@media only screen and (max-width :550px) {
    .medium-card-h {
        min-height: 500px;
        max-height: 600px;
    }

    .card-content .large-card-img {
        height: max-content;
    }

    .small-card-content {
        width: 100%;
    }

    .outils {
        background-size: contain;
        min-height: 175px;
    }
    
    .gifts {
        aspect-ratio: 13 / 4 ;
    }

    .small-card-img-container {
        max-width: 405px;
    }

    .card-content .large-card-img {
        width: 86%;
    }

    .hero-contact-buttons {
        flex-direction: column;
    }

    /* .questions-container .question-data:not(:first-child) {
        max-height: 100px !important;
    } */
}